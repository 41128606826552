import React from "react"
import Layout from "../../layout/layout"

const SummitInstituteGDPRPrivacyPolicy = () => {
  const center = {
    textAlign: "center",
  }
  const noMargin = {
    marginLeft: "0px",
  }
  const abc = {
    listStyleType: "lower-alpha",
  }

  return (
    <div className="terms">
      <Layout title="Summit Institute GDPR Privacy Policy" description="Summit Institute GDPR Privacy Policy">
        <div className="terms__container layout-container">
          <h1 style={center}>Summit Institute GDPR Privacy Policy</h1>
          <div style={center}>Last Updated: 1st January 2025</div>
          <div style={center} className="terms__info">
          At Summit Institute, we are committed to protecting the privacy and security of our students’ personal data. This Privacy Policy explains how we collect, use, store, and protect your personal data in compliance with the General Data Protection Regulation (GDPR) and relevant New Zealand data protection laws.
          </div>
          <ol>
            <li className="terms__info">
              <b>Who We Are:</b><br></br>Summit Institute is a tertiary (higher education) institution based in New Zealand. Our mission is to provide quality education while ensuring the responsible handling of personal data.
            </li>
            <li className="terms__info">
              <b>What Data We Collect:</b><br></br><b>We collect and process the following categories of personal data:</b><br></br>
Full Name<br></br>
Email Address<br></br>
Phone Number<br></br>
Date of Birth<br></br>
Residential Address<br></br>
Postal Address<br></br>
Other data necessary for the registration of learners and reporting of achievement<br></br>
We do not collect payment or financial information.
            </li>
            <li className="terms__info">
              <b>Purpose of Data Collection:</b><br></br>We collect personal data for the following purposes:
Providing personalised feedback on learning and achievement.
Reporting achievement results to the Ministry of Education and the New Zealand Qualifications Authority (NZQA), as required by law.
Ensuring compliance with regulatory and academic standards.
We do not use personal data for marketing purposes without explicit consent.
            </li>
            <li className="terms__info">
              <b>Legal Basis for Processing:</b><br></br><b>Under GDPR, we rely on the following legal bases to process your data:</b><br></br>
Contractual Obligation: Processing is necessary for delivering educational services.<br></br>
Legal Obligation: We are required to report student achievement to the Ministry of Education and NZQA.
Legitimate Interests: To provide a personalised learning experience and academic support.
            </li>
            <li className="terms__info">
              <b> Data Retention:</b><br></br>Summit Institute retains student data for 7 years to meet legal and regulatory obligations. After this period, data is securely deleted unless further retention is required by law.
            </li>
            <li className="terms__info">
              <b>Data Sharing & Third Parties:</b><br></br>We do not share your personal data with third parties, except where required by law for regulatory reporting (e.g., Ministry of Education, NZQA). We do not sell, rent, or otherwise distribute student data to commercial entities.
            </li>
            <li className="terms__info">
              <b>Data Security:</b><br></br><b>We implement industry-standard security measures to protect your data, including:</b><br></br>
Encryption of stored and transmitted data.<br></br>
Restricted access to personal data.<br></br>
Regular audits and security assessments.
            </li>
            <li className="terms__info">
              <b>Your Rights Under GDPR:</b><br></br><b>If you are an individual in the European Union, you have the following rights under GDPR:</b><br></br>
Right to Access: Request a copy of your personal data.<br></br>
Right to Rectification: Request corrections to inaccurate data.<br></br>
Right to Erasure: Request deletion of your data (subject to legal obligations).<br></br>
Right to Restriction: Request limitation on processing your data.<br></br>
Right to Data Portability: Request transfer of your data to another provider.<br></br>
Right to Object: Object to data processing in certain circumstances.<br></br>
To exercise any of these rights, please contact us at <a
              href="mailto:contact@summitinstitute.ac.nz"
              className="info-link"
            >
              contact@summitinstitute.ac.nz
            </a>
            </li>
            <li className="terms__info">
              <b>International Data Transfers:</b><br></br>Summit Institute is based in New Zealand; however, we use cloud-based services, including Google Services, which may store and process data in secure data centres located internationally. We ensure that all international data transfers comply with GDPR and relevant data protection laws, with appropriate security measures in place to protect personal information.
            </li>
            <li className="terms__info">
              <b>Contact Information:</b><br></br>For any privacy-related inquiries, including requests under GDPR, please contact: <a
              href="mailto:contact@summitinstitute.ac.nz"
              className="info-link"
            >
              contact@summitinstitute.ac.nz
            </a>
            </li>
            <li className="terms__info">
              <b>Policy Updates:</b><br></br><b>We may update this Privacy Policy from time to time. Any changes will be posted on our website with an updated effective date.</b><br></br>
              By using our services, you acknowledge that you have read and understood this Privacy Policy and agree to the terms outlined above.
            </li>
          </ol>
        </div>
      </Layout>
    </div>
  )
}

export default SummitInstituteGDPRPrivacyPolicy
